import React, { useEffect } from "react";
import BrandSvg from "../assets/icons/brand-1.svg";
import Background from "../assets/images/login-background.jpg";
import { login } from "../store/thunks/auth";
import { useNavigate } from "react-router-dom";
import Field from "../components/form/formik/Field";
import { emptyValues, Auth, validationSchema } from "../models/Auth";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { TypesOfInput } from "../types/IField";

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuth } = useAppSelector((state) => state.authSlice);

  useEffect(() => {
    if (isAuth) {
      navigate("/dashboard");
    }
  }, [isAuth]);

  const handleSubmit = (values: Auth) => {
    dispatch(login(values));
  };

  return (
    <div className="container-fluid px-0">
      <div className="row gx-0 min-vh-100">
        <div className="offset-4 col-md-9 col-lg-6 col-xl-4 px-5 d-flex align-items-center shadow">
          <div className="w-100 py-5">
            <div className="text-center">
              <h1 className="h4 text-uppercase mb-5">Admin CRM</h1>
            </div>
            <Formik
              initialValues={emptyValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              <Form className="w-100 px-4">
                <div className="mb-3">
                  <Field size={12} name="email" />
                </div>
                <div className="mb-4">
                  <Field size={12} name="password" type={TypesOfInput.PASSWORD} />
                </div>
                <Button
                  type="submit"
                  className="btn btn-primary text-uppercase w-100"
                  variant="primary"
                >
                  Sign in
                </Button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
